import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Message, SavedMessage, SavedMessageId } from '../interface/message.interface';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  private readonly MAX = 10;

  count: BehaviorSubject<number>;
  messagesValues: Message[];
  messages: BehaviorSubject<Message[]>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
  ) {
    this.messagesValues = [];
    this.messages = new BehaviorSubject<Message[]>(this.messagesValues);
  }

  getMessages() {
    console.log(`getting messages`);
    return this.afs.collection<SavedMessageId>(this.getCollectionString()).snapshotChanges().pipe(
      map(actions => actions.map((a) => {
        const data = a.payload.doc.data() as SavedMessage;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  async setMessage(message: SavedMessageId) {
    const tempMessage = Object.assign({}, message);
    delete (tempMessage as SavedMessageId).id;
    try {
      await this.afs.doc<SavedMessageId>(`users/${this.afAuth.auth.currentUser.uid}/messages/${message.id}`)
      .set(tempMessage, { merge: true });
    } catch (err) {
      console.log(`setMessage error`);
      console.error(err);
    }
  }

  async deleteMessage(savedMessage: SavedMessageId) {
    console.log(`Deleting ${savedMessage.id}`);
    try {
      await this.afs.doc<SavedMessage>(`${this.getCollectionString()}${savedMessage.id}`).delete();
    } catch (err) {
      console.log('delete error');
      console.error(err);
    }
  }

  private getCollectionString() {
    return `users/${this.afAuth.auth.currentUser.uid}/messages/`;
  }

}
