import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessagesService } from 'src/app/service/messages.service';
import { SavedMessageId } from 'src/app/interface/message.interface';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.less', '../../app.component.less']
})
export class MessagesComponent implements OnInit, OnDestroy {

  error: string;
  savedMessages: SavedMessageId[];

  message;

  constructor(
    private messagesService: MessagesService,
  ) { }

  ngOnInit() {
    this.message = this.messagesService.getMessages().subscribe((savedMessages) => {
      savedMessages.forEach((savedMessage) => {
        if (!savedMessage.dismissed) {
          savedMessage.dismissed = true;
          this.messagesService.setMessage(savedMessage);
        }
      });
      this.savedMessages = savedMessages;
    });
  }

  ngOnDestroy() {
    console.log(`Unsubscribing on destroy`);
    this.message.unsubscribe();
  }

  delete(savedMessage: SavedMessageId) {
    console.log(`Deleting saved message with title ${savedMessage.id}`);
    this.messagesService.deleteMessage(savedMessage);
  }

}
