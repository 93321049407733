import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlConstant } from '../../constant/url.constant';
import { MessagesService } from 'src/app/service/messages.service';
import { count } from 'rxjs/operators';
@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.less']
})
export class HeaderMenuComponent implements OnInit, OnDestroy {

  hidden = true;

  homeUrl: string;
  orgUrl: string;
  messagesUrl: string;

  homeDisabled = true;
  dashboardDisabled = true;
  messagesDisabled = true;

  count: number;

  backgroundMessages;

  constructor(
    private afAuth: AngularFireAuth,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private messagesService: MessagesService,
  ) {
    this.count = 0;
  }

  ngOnInit() {

    this.backgroundMessages = this.messagesService.getMessages().subscribe((messages) => {
      this.count = 0;
      messages.forEach((message) => {
        if (!message.dismissed) {
          this.count++;
        }
      });
    });

    // TODO(Kelosky): is subscribe really needed here?  cant we do this?
    // const organizationRoute = this.activatedRoute.snapshot.paramMap.get('organization');
    // const containerRoute = this.activatedRoute.snapshot.paramMap.get('container');
    this.activatedRoute.url.subscribe((urlValue) => {

      console.log(`url value is ${urlValue}, items are ${urlValue.length}`);

      if (urlValue.length > 0) {
        this.hidden = false;

        this.orgUrl = `${UrlConstant.URL_ORG_BASE}/${urlValue[1]}`;
        this.homeUrl = `${UrlConstant.URL_CHECK_BASE}/${urlValue[1]}`;
        this.messagesUrl = `${UrlConstant.URL_MESSAGES_BASE}/${urlValue[1]}`;

        if (urlValue[0].toString() === UrlConstant.URL_CHECK_BASE) {
          this.homeDisabled = true;
          this.dashboardDisabled = false;
          this.messagesDisabled = false;
        } else if (urlValue[0].toString() === UrlConstant.URL_ORG_BASE) {
          this.homeDisabled = false;
          this.dashboardDisabled = true;
          this.messagesDisabled = false;
        } else {
          this.homeDisabled = false;
          this.dashboardDisabled = false;
          this.messagesDisabled = true;
        }

      } else {
        this.hidden = true;
      }

    });
  }

  ngOnDestroy() {
    this.backgroundMessages.unsubscribe();
  }

  logout() {
    this.afAuth.auth.signOut();
  }

  home() {
    console.log(`navigating to ${this.homeUrl}`);
    this.router.navigateByUrl(this.homeUrl);
  }

  dashboard() {
    console.log(`navigating to ${this.orgUrl}`);
    this.router.navigateByUrl(this.orgUrl);
  }

  notifications() {
    console.log(`navigating to ${this.messagesUrl}`);
    this.router.navigateByUrl(this.messagesUrl);
  }

}
