import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Container, ContainerId } from '../interface/container.interface';
import { OrganizationId } from '../interface/organization.interface';

@Injectable({
  providedIn: 'root'
})
export class ContainersService {

  constructor(
    private afs: AngularFirestore,
  ) { }

  getContainers(org: OrganizationId) {
    console.log(`organizations/${org.id}/containers/`);
    return this.afs.collection<ContainerId>(`organizations/${org.id}/containers/`)
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Container;
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      );
  }

  getContainersWhere(org: OrganizationId, containerRoute: string) {
    console.log(`getting organizations`);
    return this.afs.collection<ContainerId>(`organizations/${org.id}/containers`, ref => ref.where('uri', '==', containerRoute))
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Container;
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      );
  }

  // TODO(Kelosky): ensure uniqueness on URI
  async addContainer(org: OrganizationId, container: Container) {
    console.log(`adding container: ${container.name}`);
    try {
      await this.afs.collection<Container>(`organizations/${org.id}/containers`).add(container);
    } catch (err) {
      console.log(`addContainer error`);
      console.error(err);
    }
  }

  // TODO(Kelosky): set certain fields
  async setContainer(org: OrganizationId, container: ContainerId) {
    console.log(`Updating ${container.name}, id: ${container.id}`);
    const tempContainer = Object.assign({}, container);
    delete tempContainer.id;
    try {
      await this.afs.doc<Container>(`organizations/${org.id}/containers/${container.id}`).set(tempContainer);
    } catch (err) {
      console.log('set error');
      console.error(err);
    }
  }

  async deleteContainer(org: OrganizationId, container: ContainerId) {
    console.log(`Deleting ${container.name}, id: ${container.id}`);
    try {
      await this.afs.doc<Container>(`organizations/${org.id}/containers/${container.id}`).delete();
    } catch (err) {
      console.log('delete error');
      console.error(err);
    }
  }

  getContainerRef(org: OrganizationId, container: ContainerId) {
    return this.afs.doc<Container>(`organizations/${org.id}/containers/${container.id}`).ref;
  }

}
