import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContainerId } from 'src/app/interface/container.interface';
import { ContainersService } from 'src/app/service/containers.service';
import { OrganizationsService } from 'src/app/service/organizations.service';
import { ChildId, ChildParent } from 'src/app/interface/child.interface';
import { Observable, Subscription } from 'rxjs';
import { ToggleService } from 'src/app/service/toggle.service';
import { OrganizationId } from 'src/app/interface/organization.interface';
import { UrlConstant } from 'src/app/constant/url.constant';
import { MatDialog } from '@angular/material';
import { PhoneNumberComponent } from '../phone-number/phone-number.component';
import { MessageParentComponent } from '../message-parent/message-parent.component';

interface ChildParentRefPair {
  child: ChildParent;
  ref: boolean;
}

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.less', '../../app.component.less']
})
export class ContainerComponent implements OnInit, OnDestroy {
  children: Map<string, ChildParentRefPair>;

  organization: OrganizationId;

  container: ContainerId;
  error: string;

  $children: Observable<ChildId[]>;
  $containers: Observable<ContainerId[]>;

  toggle: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private organizationsService: OrganizationsService,
    private containersService: ContainersService,
    private toggleService: ToggleService,
    private router: Router
  ) {

    this.children = new Map<string, ChildParentRefPair>();
    const organizationRoute = this.activatedRoute.snapshot.paramMap.get('organization');
    const containerRoute = this.activatedRoute.snapshot.paramMap.get('container');
    console.log(`Init for ${organizationRoute}/${containerRoute}`);

    this.organizationsService.getOrganizationsWhere(organizationRoute).subscribe((orgs) => {

      if (orgs.length === 1) {
        this.organization = orgs[0];

        // get observables from database
        this.containersService.getContainersWhere(this.organization, containerRoute).subscribe(async (containers) => {

          if (containers.length === 1) {
            this.error = undefined;
            this.container = containers[0];

            for (const childRef of this.container.checkedin) {
              const doc = await childRef.get();
              this.children.set(doc.id, {
                child: {
                  name: doc.data().name,
                  id: doc.id,
                  gender: doc.data().gender,
                  phone: doc.data().phone,
                  age: doc.data().age,
                  important: doc.data().important,
                  parentId: doc.ref.parent.parent.id,
                },
                ref: true
              });
            }

            // build a list of things not referenced
            const delList = [];
            this.children.forEach((pair) => {

              // reset everything that was refreshed
              if (pair.ref) {
                pair.ref = false;

              // prepare to delete items that were not
              } else {
                delList.push(pair.child.id);
              }
            });

            // if not referenced, mark as deleted
            delList.forEach((del) => {
              this.children.delete(del);
            });
          } else if (orgs.length > 1) {
            this.error = `Unexpected same named organization, total: ${orgs.length}`;
            this.container = undefined;
          } else {
            this.error = `'/${organizationRoute}/${containerRoute}' entry does not exist`;
            this.container = undefined;
          }
        });
      } else if (orgs.length > 1) {
        this.error = `Unexpected same named organization, total: ${orgs.length}`;
      } else {
        this.error = `'/${organizationRoute}' entry does not exist`;
      }
    });

    this.toggle = this.toggleService.toggle.subscribe(() => {

      const url = `/${UrlConstant.URL_ORG_BASE}/${organizationRoute}`;
      console.log(`navigating to ${url}`);
      this.router.navigateByUrl(url);
    });

  }

  ngOnDestroy() {
    this.toggle.unsubscribe();
  }

  ngOnInit() {
  }

  alert(child: ChildId) {
    this.dialog.open(MessageParentComponent, { data: { child, organization: this.organization } });
  }

  call(child: ChildId) {
    this.dialog.open(PhoneNumberComponent, { data: child });
  }

}
