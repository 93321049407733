import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBarConfig, MatSnackBar } from '@angular/material';
import { ChildParent } from 'src/app/interface/child.interface';
import { functions } from 'firebase';
import { OrganizationId } from 'src/app/interface/organization.interface';

interface MessageParentData {
  organization: OrganizationId;
  child: ChildParent;
}

@Component({
  selector: 'app-message-parent',
  templateUrl: './message-parent.component.html',
  styleUrls: ['./message-parent.component.less']
})
export class MessageParentComponent implements OnInit {

  childForm = this.fb.group({
    message: ['', Validators.required],
  });


  constructor(
    public dialogRef: MatDialogRef<MessageParentComponent>,
    private fb: FormBuilder,
    private sb: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: MessageParentData,
  ) {  }

  ngOnInit() {
  }

  submit() {
    console.log(`Form input: ${JSON.stringify(this.childForm.value, null, 2)}`);
    console.log(`parent: ${this.data.child.parentId}`);

    try {
      functions().httpsCallable('messageParent')({
        parentId: this.data.child.parentId,
        childName: this.data.child.name,
        orgName: this.data.organization.name,
        message: this.childForm.value.message,
      });

    } catch (err) {
      console.error(`Notify failure`);
      console.error(err);
    }

    const message = `Sent message for ${this.data.child.name}!`;
    const config: MatSnackBarConfig = {
      duration: 1000 * 1.5
    };
    this.dialogRef.close();
    this.sb.open(message, null, config);
  }

  cancel() {
    this.dialogRef.close();
  }
}
