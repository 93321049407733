import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { UrlConstant } from '../../constant/url.constant';
import { Observable, Subject } from 'rxjs';
import { OrganizationId } from 'src/app/interface/organization.interface';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { OrganizationsService } from 'src/app/service/organizations.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less', '../../app.component.less']
})
export class HomeComponent implements OnInit, OnDestroy {

  organizations$: Observable<OrganizationId[]>;
  private searchTerms = new Subject<string>();
  loading = false;
  sub;
  error;

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private organizationsService: OrganizationsService,
  ) {
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.organizations$ = this.searchTerms.pipe(

      // wait 300ms after each keystroke before considering the term
      debounceTime(300),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((term: string) => this.organizationsService.getOrganizationsBetweenWhere(term)
      ),
    );

    this.sub = this.organizations$.subscribe(() => {
      this.loading = false;
    });
  }

  search(term: string): void {
    this.loading = true;
    this.searchTerms.next(term);
  }

  route(org: OrganizationId) {
    this.router.navigateByUrl(`${UrlConstant.URL_CHECK_BASE}/${org.name}`);
  }

}
